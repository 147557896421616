<template>
  <!-- <v-main style="padding: 50px 0px 84px 260px;"> -->
  <v-main :style="{background: $vuetify.theme.themes[theme].background}">
    <v-container fluid>
      <v-slide-x-transition mode="out-in" duration="500">
        <router-view :key="$route.path" />
      </v-slide-x-transition>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'DefaultView',
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  }
}
</script>
